import React from 'react'
import styled from 'styled-components'

const SocialNetworkListItem = styled.li`
  padding: 5px;
`

const StyledSocialNetworkIconsList = styled.ul`
  display: inline-flex;
`

const SocialNetwork = ({ social }) => (
  <SocialNetworkListItem key={social.name}>
    <a href={social.url} title={social.alt}>
      <i className={social.className} />
    </a>
  </SocialNetworkListItem>
)

const SocialNetworks = ({ socials = [] }) => (
  <StyledSocialNetworkIconsList className='social'>
    {socials.map(social => (
      <SocialNetwork key={`${social.name}-${social.url}`} social={social} />
    ))}
  </StyledSocialNetworkIconsList>
)

export default SocialNetworks
