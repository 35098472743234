import React, { Component } from 'react'

import SocialNetworks from './SocialNetworks'

class Header extends Component {
  render() {
    const { name, occupation, socials } = this.props

    return (
      <header id='home'>
        <nav id='nav-wrap'>
          <a className='mobile-btn' href='#nav-wrap' title='Show navigation'>
            Show navigation
          </a>
          <a className='mobile-btn' href='#home' title='Hide navigation'>
            Hide navigation
          </a>

          <ul id='nav' className='nav'>
            <li>
              <a href='#home' title='Home'>
                Home
              </a>
            </li>
            <li>
              <a href='#about' title='About'>
                About
              </a>
            </li>
            <li>
              <a href='#experience' title='Experience'>
                Experience
              </a>
            </li>
            <li>
              <a href='#recommendations' title='Recommendations'>
                Recommendations
              </a>
            </li>
            <li>
              <a href='#contact' title='Contact'>
                Contact
              </a>
            </li>
          </ul>
        </nav>

        <div className='row banner'>
          <div className='banner-text'>
            <h1 className='responsive-headline'>I'm {name}.</h1>
            <h3>
              I'm a remote <span>{occupation}</span>.
            </h3>
            <SocialNetworks socials={socials} />
          </div>
        </div>

        <p className='scrolldown'>
          <a href='#about' title='About'>
            <i className='icon-down-circle' />
          </a>
        </p>
      </header>
    )
  }
}

export default Header
