import React, { Component } from 'react'

class Contact extends Component {
  render() {
    const { email, contactMessage } = this.props

    return (
      <section id='contact'>
        <div className='row section-head'>
          <div className='three columns header-col'>
            <h1>
              <span>{contactMessage}</span>
            </h1>
          </div>
          <div className='nine columns'>
            <div>
              <h4>Email</h4>
              <p className='email'>
                <span>
                  <a href={`mailto:${email}`}>{email}</a>
                </span>
              </p>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Contact
