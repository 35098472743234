import React, { Component } from 'react'

class About extends Component {
  render() {
    const { name, profilePictureUrl, bio, email, resumeUrl } = this.props

    return (
      <section id='about'>
        <div className='row'>
          <div className='three columns'>
            <img
              className='profile-pic'
              src={profilePictureUrl}
              alt={`${name} Profile Pic`}
            />
          </div>
          <div className='nine columns main-col'>
            <h2>About Me</h2>

            <p>{bio}</p>
            <div className='row'>
              <div className='columns contact-details'>
                <h2>Contact Details</h2>
                <p className='address'>
                  <span>
                    <a href={`mailto:${email}`}>{email}</a>
                  </span>
                </p>
              </div>
              <div className='columns download'>
                <p>
                  <a href={resumeUrl} className='button'>
                    <i className='fa fa-download' />
                    Download Resume
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default About
