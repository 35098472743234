import React, { Component } from 'react'
import ReactGA from 'react-ga'

import Header from './Components/Common/Header'
import Footer from './Components/Common/Footer'
import About from './Components/Pages/About'
import Resume from './Components/Pages/Resume'
import Contact from './Components/Pages/Contact'

import resume from './resumeData.json'

class App extends Component {
  constructor(props) {
    super(props)

    ReactGA.initialize('UA-135680270-1')
    ReactGA.pageview(window.location.pathname)
  }

  render() {
    const {
      name,
      occupation,
      socials,
      profilePictureUrl,
      bio,
      email,
      resumeUrl,
      contactMessage,
      recommendations,
      employmentHistory,
    } = resume

    return (
      <div className='App'>
        <Header name={name} occupation={occupation} socials={socials} />
        <About
          name={name}
          profilePictureUrl={profilePictureUrl}
          bio={bio}
          email={email}
          resumeUrl={resumeUrl}
        />
        <Resume
          recommendations={recommendations}
          employmentHistory={employmentHistory}
        />
        <Contact email={email} contactMessage={contactMessage} />
        <Footer socials={socials} />
      </div>
    )
  }
}

export default App
