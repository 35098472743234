import React, { Component } from 'react'
import SocialNetworks from './SocialNetworks'

class Footer extends Component {
  render() {
    const { socials = [] } = this.props

    return (
      <footer>
        <div className='row'>
          <div className='twelve columns'>
            <SocialNetworks socials={socials} />

            <ul className='copyright'>
              <li>&copy; Copyright 2019 Scott Warren</li>
              <li>
                Resume Template by{' '}
                <a
                  title='Resume Template'
                  href='https://github.com/tbakerx/react-resume-template'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Tim Baker
                </a>
              </li>
              <li>
                Design by{' '}
                <a
                  title='Styleshout'
                  href='http://www.styleshout.com/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Styleshout
                </a>
              </li>
            </ul>
          </div>
          <div id='go-top'>
            <a title='Back to Top' href='#home'>
              <i className='icon-up-open' />
            </a>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
