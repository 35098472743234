import React, { Component } from 'react'

class Resume extends Component {
  render() {
    const { recommendations, employmentHistory } = this.props

    return (
      <div className='resume-container'>
        <section id='experience'>
          <div className='row work'>
            <div className='three columns header-col'>
              <h1>
                <span>Experience</span>
              </h1>
            </div>

            <div className='nine columns main-col'>
              {employmentHistory.map(job => (
                <div key={`${job.company}-${job.title}-${job.years}`}>
                  <h3>{job.company}</h3>
                  <p className='info'>
                    {job.title}
                    <span>&bull;</span> <em className='date'>{job.years}</em>
                  </p>
                  <p>{job.description}</p>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section id='recommendations'>
          <div className='row recommendations'>
            <div className='three columns header-col'>
              <h1>
                <span>Recommendations</span>
              </h1>
            </div>

            <div className='nine columns main-col'>
              <p>Recommendations from past colleagues and managers</p>

              <div className='bars'>
                <ul className='recommendations-list'>
                  {recommendations.map(testimonial => (
                    <li key={testimonial.user}>
                      <blockquote>
                        <p>{testimonial.text}</p>
                        <cite>{testimonial.user}</cite>
                      </blockquote>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

export default Resume
